<script setup>
import Container from "@/Components/Shared/Container.vue";
import route from "@/Helpers/route.js";
import CardTitle from "@/Components/Shared/Cards/CardTitle.vue";
import Card from "@/Components/Shared/Cards/Card.vue";
import TextInput from "@/Components/Shared/Forms/TextInput.vue";
import InputError from "@/Components/Shared/Forms/InputError.vue";
import InputLabel from "@/Components/Shared/Forms/InputLabel.vue";
import CardButton from "@/Components/Shared/Cards/CardButton.vue";
import {useForm, usePage} from "@inertiajs/vue3";

const page = usePage();

const props = defineProps({
    restaurant: Object,
    honeypot: Object,
});

const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    [props.honeypot.nameFieldName]: '',
    [props.honeypot.validFromFieldName]: props.honeypot.encryptedValidFrom,
});

const submit = () => form.post(route(`${page.props.routing_group}.newsletter.store`, {
    [page.props.routing_key]: page.props.routing_target,
}), {
    preserveScroll: true
});
</script>

<template>
    <Container class="!max-w-[1000px]">
        <h2 class="block text-gray-700 font-bold text-[26px] md:text-[32px] mb-4">Up to date blijven?</h2>

        <Card>
            <div class="p-6">
                <CardTitle class="mb-4">Schrijf je in voor onze nieuwsbrief.</CardTitle>

                <form @submit.prevent="submit">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <InputLabel for="first_name" value="Voornaam" />
                            <TextInput id="first_name" type="text" class="block w-full" v-model="form.first_name" required dusk="first_name" />
                            <InputError class="mt-2" :message="form.errors.first_name" />
                        </div>

                        <div>
                            <InputLabel for="last_name" value="Achternaam" />
                            <TextInput id="last_name" type="text" class="block w-full" v-model="form.last_name" required dusk="last_name" />
                            <InputError class="mt-2" :message="form.errors.last_name" />
                        </div>
                    </div>

                    <div>
                        <InputLabel for="email" value="E-mailadres" />
                        <TextInput id="email" type="email" class="block w-full" v-model="form.email" required dusk="email" />
                        <InputError class="mt-2" :message="form.errors.email" />
                    </div>

                    <div v-if="honeypot.enabled" :name="`${honeypot.nameFieldName}_wrap`" class="hidden">
                        <input type="text" v-model="form[honeypot.nameFieldName]" :name="honeypot.nameFieldName" :id="honeypot.nameFieldName" />
                        <input type="text" v-model="form[honeypot.validFromFieldName]" :name="honeypot.validFromFieldName" />
                    </div>
                </form>
            </div>

            <button
                class="
                    flex w-full h-16 justify-center items-center
                    cursor-pointer font-medium
                    rounded-b-xl rounded-t-none
                    transition ease-in-out duration-150
                    bg-[var(--primary)] text-white
                "
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                @click="submit">
                Inschrijven
            </button>
        </Card>
    </Container>
</template>
